import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LinkGrid from '../../components/terms/link-grid'
import PolicyDetails from '../../components/terms/trademark-disputes'

const TrademarkDisputes = ({ location }) => {
  return (
    <Layout isTallFooter>
      <SEO
        title='Trademark Disputes for Domain Names'
        pageType='terms'
        flowType='none'
      />
      {typeof window !== 'undefined' && (
        <LinkGrid pathname={location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default TrademarkDisputes
